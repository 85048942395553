<template>
  <div>
    <v-card>
      <v-card-title
        >Tipos de Eventos
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="edit({})">
          <v-icon>mdi-plus</v-icon> Novo Evento
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Id</th>
              <th class="text-left">Nome</th>
              <th class="text-left">Cor</th>
              <th class="text-left">ContactId</th>
              <th class="text-left">Monitoramento</th>
              <th class="text-left">Cadastrado</th>
              <th class="text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getEventosTipos" :key="item.name">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>
                <v-chip :color="item.color">{{ item.color }}</v-chip>
              </td>
              <td>{{ item.contactId }}</td>
              <td>
                <!-- <v-card>
                  <v-card-text> -->
                    <v-row no-gutters>
                      <v-col>
                        Mostrar Evento:
                        {{ item.showEvent ? "SIM" : "NÃO" }}
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col>
                        Enviar para o Monitoramento:
                        {{ item.sendEvent ? "SIM" : "NÃO" }}
                      </v-col>
                    </v-row>
                  <!-- </v-card-text>
                </v-card> -->
              </td>
              <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="edit(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="del(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          {{ evento.id ? "Editar" : "Cadastrar" }} Evento
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Nome do Evento"
                v-model="evento.name"
                :counter="50"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Cor do Evento"
                v-model="evento.color"
                type="color"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="ContactId"
                v-model="evento.contactId"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox
                label="Mostrar Evento"
                v-model="evento.showEvent"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                label="Enviar Monitoramento"
                v-model="evento.sendEvent"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="warning" text @click="closeDialog"> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TipoEventos",
  components: {},
  data: () => {
    return {
      dialog: false,
      evento: { color: "#000000" },
    };
  },
  methods: {
    del(item) {
      // console.log("del", item);
      this.$store.dispatch("deletEventoTipo", item);
    },
    edit(item) {
      // console.log("edt", item);
      this.evento = item;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.evento = {};
    },
    save() {
      this.$store.dispatch("saveEventoTipo", {
        ...this.evento,
        cb: this.closeDialog,
      });
    },
  },
  computed: {
    ...mapGetters(["getEventosTipos"]),
  },
  mounted() {
    this.$store.dispatch("loadEventosTipos");
  },
};
</script>

<style>
</style>