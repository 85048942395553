<template>
  <div>
    <v-card>
      <v-card-title
        >Eventos Câmeras
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="edit({})">
          <v-icon>mdi-plus</v-icon> Novo Evento
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Código Câmera</th>
              <th class="text-left">Modelo Câmera</th>
              <th class="text-left">Tipo Evento</th>
              <th class="text-left">Cadastrado</th>
              <th class="text-left">Editado</th>
              <th class="text-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getEventosCameras" :key="item.name">
              <td>{{ item.id }}</td>
              <td>{{ item.code }}</td>
              <td>{{ getCameraTypeById(item.modelId) }}</td>
              <td>
                <v-chip :color="getChipEventColor(item.evtTypeId)">
                  {{ getEventosTiposById(item.evtTypeId) }}
                </v-chip>
              </td>
              <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
              <td>
                {{
                  item.updatedAt
                    ? new Date(item.updatedAt).toLocaleString()
                    : ""
                }}
              </td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="edit(item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="del(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Excluir</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title> Vincular Evento </v-card-title>
        <v-card-subtitle> Clique na linha para vincular </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Codigo</th>
                      <th class="text-left">Modelo Câmera</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in getEventosTiposAvaliables"
                      :key="item.name"
                      @click="setEvt(item)"
                    >
                      <td>{{ item.code }}</td>
                      <td>{{ getCameraTypeById(item.modelId) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
              v-model="evento.evtTypeId"
                :items="getEventosTiposSelect"
                label="Selecione o Tipo do Evento"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row align-content="center">
            <v-col>
              <v-input messages="Código da Câmera"> {{evento.code}} </v-input>
            </v-col>
            <v-col>
              <v-input messages="Modelo da Câmera"> {{getCameraTypeById(evento.modelId)}} </v-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="warning" text @click="closeDialog"> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventosCameras",
  components: {},
  data: () => {
    return {
      dialog: false,
      evento: {},
    };
  },
  methods: {
    del(item) {
      // console.log("del", item);
      this.$store.dispatch('deletEventoCamera', item)
    },
    edit(item) {
      // console.log("edt", item);
      this.evento = item;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.evento = {};
    },
    save() {
      this.$store.dispatch("saveEventoCamera", {
        ...this.evento,
        cb: this.closeDialog,
      });
    },
    setEvt(item){
        this.evento = {
            modelId : item.modelId,
            code: item.code
        }
    },
    getChipEventColor(id) {
      let evt = this.getEventosTipos.find((g) => g.id == id);
      if (evt) {
        return evt.color;
      } else {
        return null;
      }
      //   return evt
    },
  },
  computed: {
    ...mapGetters([
      "getEventosCameras",
      "getCameraTypeById",
      "getEventosTiposById",
      "getEventosTipos",
      "getEventosTiposAvaliables",
      "getEventosTiposSelect",
    ]),
  },
  mounted() {
    this.$store.dispatch("loadEventosCameras");
    this.$store.dispatch("loadCamTypes");
    this.$store.dispatch("loadEventosTipos");
    this.$store.dispatch("loadEventosAvailable");
  },
};
</script>

<style>
</style>