<template>
  <div>
    <v-card>
      <v-card-title> Configuração dos Eventos </v-card-title>
      <v-card-text>
        <v-tabs fixed-tabs background-color="primary" dark>
          <v-tab> Eventos Câmeras </v-tab>
          <v-tab-item> <EventosCameras /> </v-tab-item>
          <v-tab> Tipos de Eventos </v-tab>
          <v-tab-item> <TipoEventos /> </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import TipoEventos from "../components/ConfigEventos/TipoEventos.vue";
import EventosCameras from "../components/ConfigEventos/EventosCameras.vue";
export default {
  name: "ConfigEventos",
  components: {
    TipoEventos,
    EventosCameras,
  },
  data: () => {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style>
</style>